import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { environment } from '../../../environments/environment';

export interface statementResponse {
  pdfContents: string;
}

export interface statementRequest {
  statementType: string;
  startDate: Date | string | null;
  endDate: Date | string | null;
}

@Injectable({
  providedIn: 'root',
})
export class StatementService {
  endPointBase: string = '/Statement';

  response: statementResponse = {
    pdfContents: '',
  };

  loading: boolean = false;
  complete: boolean = false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private responseSubject = new BehaviorSubject<statementResponse | any>(null);
  response$ = this.responseSubject.asObservable();

  constructor(private http: HttpClient) {}

  fetchStatement(statementRequest: statementRequest) {
    this.loading = true;
    this.http
      .post<statementResponse>(
        environment.apiUrl + this.endPointBase,
        {
          statementType: statementRequest.statementType,
          startDate: statementRequest.startDate,
          endDate: statementRequest.endDate,
        },
        {
          observe: 'response',
        }
      )
      .subscribe({
        next: (res: HttpResponse<statementResponse>) => {
          if (res.status === 200 && res.body) {
            this.loading = false;
            this.response = res.body;
            this.complete = true;

            const date = new Date();
            const year = date.getUTCFullYear();
            const month = String(date.getUTCMonth() + 1).padStart(2, '0');
            const day = String(date.getUTCDate()).padStart(2, '0');
            const hours = String(date.getUTCHours()).padStart(2, '0');
            const minutes = String(date.getUTCMinutes()).padStart(2, '0');
            const seconds = String(date.getUTCSeconds()).padStart(2, '0');
            const formattedDate = `${year}_${month}_${day}_${hours}_${minutes}_${seconds}`;

            if (res.body.pdfContents !== '') {
              const link = document.createElement('a');
              link.href = 'data:application/pdf;base64,' + res.body.pdfContents;
              link.target = '_blank';
              link.download =
                formattedDate + '_' + statementRequest.statementType + '.pdf';
              link.dispatchEvent(new MouseEvent('click'));
            }
            this.responseSubject.next(res.body);
          }
        },
        complete: () => {
          this.loading = false;
          this.complete = true;
        },
      });
  }

  getStatement(): statementResponse {
    return this.response; // Return data stored in the service
  }
}
